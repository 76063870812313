import { useNavigate, useLocation } from 'react-router-dom';

export const getSimpleBack = (pathname: string) => {
  const lastSlashIndex = pathname.lastIndexOf('/');
  const newPathName = pathname.substring(0, lastSlashIndex);

  return newPathName;
};

export const useRouterUtils = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const goSimpleBack = () => {
    navigate(getSimpleBack(pathname));
  };

  return {
    goSimpleBack,
  };
};
