import { toast } from 'react-toastify';
import { ReactNode } from 'react';
import { parseResponseErrors } from '../shared/utils/parseResponseErrors';
import { Toast } from '@r-youth/crm-ui';

export type DoRequestOptions = {
  showError?: boolean;
  showSuccess?: boolean;
  successMsg?: ReactNode;
};

export type DoRequestReturn<T = unknown> = Promise<{
  data: T;
  errors: any;
  status?: string;
  success: boolean;
}>;

const isAdminPages = window.location.pathname.includes('admin');

export async function doRequest<T = null | any>(
  requestCallback,
  params?,
  errorMsg?,
  opts?: DoRequestOptions,
): DoRequestReturn<T> {
  opts = { ...defaultOptions, ...opts };
  const { showError, showSuccess, successMsg } = opts;

  try {
    let response = await requestCallback(params);
    const result = response?.result || response?.data?.result;

    if (result === false || response.error) {
      throw response;
    }

    const success = result !== false;

    if (success && showSuccess) {
      isAdminPages ? Toast.success(successMsg) : toast(successMsg, { type: 'success' });
    }

    return {
      data: (response || {}) as T,
      errors: null,
      success,
    };
  } catch (error) {
    if (showError) {
      const flattenedErrors = parseResponseErrors(
        error?.response?.data?.errors ||
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.message ||
          error,
      );

      isAdminPages
        ? Toast.error(
            <div className={'grid gap-4'}>
              {flattenedErrors.map((err, i) => (
                <div key={i}>{err}</div>
              ))}
            </div>,
          )
        : toast.error(
            <div className={'grid gap-4'}>
              {flattenedErrors.map((err, i) => (
                <div key={i}>{err}</div>
              ))}
            </div>,
          );
    }

    return {
      data: null as T,
      errors: error,
      success: false,
      status: error?.response?.status,
    };
  }
}

const defaultOptions = {
  successMsg: 'Сохранено',
  showError: true,
  showSuccess: false,
};
