import React from 'react';
import ReactTextMaskInput from 'react-text-mask';
import classes from './MaskedInput.module.scss';

export const MaskedInput = React.forwardRef((props: any, ref) => {
  return <ReactTextMaskInput {...props} />;
});

export const getMaskedInput = (mask, className?) =>
  React.forwardRef(({ inputRef, ...props }: any, ref) => (
    <MaskedInput mask={mask} className={className} {...props} />
  ));

export const PhoneMaskedInput = getMaskedInput([
  '+',
  '7',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]);

export const DateMaskedInput = getMaskedInput([
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]);

export const TimeMaskedInput = getMaskedInput(
  [/\d/, /\d/, ':', /\d/, /\d/],
  classes.timeMaskedInput,
);
