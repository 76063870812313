import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { useAuthentication } from 'hooks/useAuthentication';
import { useUrlSearchParams } from 'hooks/useUrlSearchParams';
import { setTaskId } from '_redux/drugoeDelo/drugoeDelo.slice';
import { PARTNER_KEY } from '@/entities/partner';
import {
  VisuallyImpairedPanel,
  VisuallyImpariedModeProvider,
} from '@/features/visuallyImpairedMode';
import { ToastContainer as AdminToastContainer } from '@r-youth/crm-ui';

const isAdminPages = window.location.pathname.includes('admin');

const RouterProviderLayout = () => {
  useAuthentication();

  const location = useLocation();
  const [searchParams] = useUrlSearchParams<{ dd_task_id: string; partner: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (searchParams.dd_task_id) {
      dispatch(setTaskId(Number(searchParams.dd_task_id!)));
    }
    if (searchParams.partner) {
      sessionStorage.setItem(PARTNER_KEY, searchParams.partner);
    }
  }, []);

  return (
    <VisuallyImpariedModeProvider>
      <VisuallyImpairedPanel />
      <CssBaseline />
      <Outlet />
      {isAdminPages ? (
        <AdminToastContainer className='admin-toast' />
      ) : (
        <ToastContainer className='lk-toast' hideProgressBar pauseOnFocusLoss={false} />
      )}
    </VisuallyImpariedModeProvider>
  );
};

export default RouterProviderLayout;
